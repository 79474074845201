<template>
  <div :class="{ 'colapsed-header': isColapsedBlock }">
    <colapse-button
      :isColapsedBlock="isColapsedBlock"
      @click="isColapsedBlock = !isColapsedBlock"
    />
    <div
      v-if="!isColapsedBlock"
      class="head-and-filters js-head-and-filters"
      :style="`right: ` + rightHeadPosition"
    >
      <header-page :title="'History'" :hasBackLink="entityId ? true : false" />

      <div class="filters-container">
        <div class="filters-block">
          <div class="search">
            <input-text v-model="search_text" />
            <div
              v-if="isShowClear"
              class="main-button lg ml-15"
              @click="clearFilters"
            >
              <div>Clear</div>
            </div>
          </div>
          <div class="button">
            <div class="main-button lg orange" @click="isVisibleFilters = true">
              <div class="icon" v-html="Filter"></div>
              <div>Filters</div>
            </div>
          </div>
        </div>
        <div class="selected-filters" v-if="selectedFilters.length">
          <div
            class="selected-filters-groups"
            v-for="(group, key) in filterGroups"
            :key="key"
          >
            <div class="selected-filters-groups-item">
              <div
                class="icon"
                @click="removeFilterGroup(key)"
                v-html="Cross"
              ></div>
              <div class="text">{{ group[0].groupName }}</div>
              <div class="selected-filters-groups-item-sub-items">
                <template v-for="groupItem in group" :key="groupItem.text">
                  <div
                    v-if="!isArray(groupItem.text)"
                    class="selected-filters-item"
                  >
                    <div
                      class="icon"
                      @click="removeFilter(groupItem.id)"
                      v-html="Cross"
                    ></div>
                    <div class="text">
                      {{ groupItem.label }} {{ groupItem.text }}
                    </div>
                  </div>

                  <div
                    v-else
                    class="selected-filters-item"
                    v-for="groupItemArray in groupItem.text"
                    :key="groupItemArray"
                  >
                    <div
                      class="icon"
                      @click="
                        removeFilterArrayItem(groupItem.id, groupItemArray)
                      "
                      v-html="Cross"
                    ></div>
                    <div class="text">{{ groupItemArray }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      v-model:selectedRows="selectedRows"
      v-model:options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      :listTabs="listTabs"
      @setTab="setTab"
      @deleteRow="() => {}"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntity"
      @saveRow="saveEntity"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @deleteSelected="() => {}"
      @exportSelected="exportSelected"
      @changedColumnsParams="changedColumnsParams"
    />
    <set-filters
      v-if="isVisibleFilters"
      v-model="isVisibleFilters"
      :setFilterComponent="setFilterComponent"
      :filters="filters"
      @setFilters="setFilters"
    />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import SetFilterComponent from '@/components/client/SetFilters/SetFiltersHistory'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'History',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    let filterKeyUrlIndex = 0
    let columns = [
      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'date_event',
        text: 'Date event',
        width: '120px',
      }),

      /*  new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'partner_id',
        text: 'Partner ID',
        width: '100px',
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'link_id',
        text: 'Link ID',
        width: '100px',
      }),
*/

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'client_id',
        text: 'Client ID',
        width: '100px',
      }),

      // new GridColumn({
      //   keyUrl: 's' + colsKeyUrlIndex++,
      //   key: 'company_name',
      //   text: 'Company name',
      //   formatedValue: ({ value, row }) => {
      //     if (value && row?.partner_id)
      //       return `<a href="${
      //         window.location.origin + '/link-builder-partner/' + row.partner_id
      //       }" target="_blank">${value}</a>`
      //     return value
      //   },
      // }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'link_builder',
        text: 'Link builder',
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'category',
        text: 'Category',
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'url',
        text: 'URL',
        cssClass: 'bold-underline hand',
        formatedValue: ({ value }) => {
          if (value)
            return `<a href="${value}" title="${value}" target="_blank">${value}</a>`
          return ''
        },
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'entity',
        text: 'Entity',
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'action',
        text: 'Action',
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'previous_value',
        text: 'Previous value',
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'current_value',
        text: 'Current value',
      }),

      new GridColumn({
        keyUrl: 's' + colsKeyUrlIndex++,
        key: 'editor',
        text: 'Editor',
      }),
    ]

    this.$nextTick(() => {
      if (this.entityId) {
        columns.push(
          new GridColumn({
            keyUrl: 's' + colsKeyUrlIndex++,
            key: 'client_name',
            text: 'Client name',
            formatedValue: ({ value, row }) => {
              if (value && row?.client_id)
                return `<a href="${
                  window.location.origin + '/clients/' + row.client_id
                }" target="_blank">${value}</a>`
              return value
            },
          })
        )
      }
    })

    return {
      options: new GrigOptions({
        isCardEditable: true,
        isExportToCSV: true, //hasAccess(permissions.export_partners_to_csv),
        addButtonText: null,
        deleteButtonText: null,
      }),

      columns: columns,
      per_page: 100,
      newRowComponent: shallowRef(/*NewRowEntity*/ null),
      setFilterComponent: shallowRef(SetFilterComponent),
      filters: {},
      listKey: 'clients-history',
      groupToFilter: {
        date_event_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_event_f',
          name: 'Date event',
        },
        date_event_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_event_f',
          name: 'Date event',
        },
        partner_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'partner_f',
          name: 'Partner',
        },
        link_builder_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_builder_f',
          name: 'Link builder',
        },
        entity_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'entity_f',
          name: 'Entity',
        },
        action_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'action_f',
          name: 'Action',
        },
        editor_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'editor_f',
          name: 'Editor',
        },
        client_name_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'client_name_f',
          name: 'Client name',
        },
        category_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'category_f',
          name: 'Category',
        },
      },
      isColapsedBlock: false,

      listTabs: [
        {
          id: 'partners-history',
          name: 'Partners history',
          //count: 0,
          isActive: false,
          isVisible: true,
        },

        {
          id: 'clients-history',
          name: 'Clients history',
          //count: 0,
          isActive: true,
          isVisible: true,
        },
      ],
    }
  },

  computed: {
    entityId() {
      if (this.$route?.params?.id === 'all' || !this.$route?.params?.id)
        return null
      else if (isNaN(this.$route?.params?.id)) return null

      return this.$route?.params?.id
    },
  },

  created() {
    this.setDefaultColumnsParams()
    this.setMetaDataColumnsParams()
    this.setDataFromQueryParams() // 3
    this.fetchData()
  },

  methods: {
    // async loadCountTabs() {
    //   let query = `page=1&per_page=1`

    //   let partnersAll = await fetchData(urls.partners, 'GET', null, query + `&partners_f=all`)
    //   let partnersAllTab =  this.listTabs.find(item => item.id === 'all')
    //   partnersAllTab.count = partnersAll?.count || 0

    //   let partnersMy = await fetchData(urls.partners, 'GET', null, query + `&partners_f=my`)
    //   let partnersMyTab =  this.listTabs.find(item => item.id === 'my-partners')
    //   partnersMyTab.count = partnersMy?.count || 0

    //   let partnersMyDiv = await fetchData(urls.partners, 'GET', null, query + `&partners_f=div`)
    //   let partnersMyDivTab =  this.listTabs.find(item => item.id === 'my-div')
    //   partnersMyDivTab.count = partnersMyDiv?.count || 0
    //   if(partnersMyDivTab.count) partnersMyDivTab.isVisible = true

    // },

    setTab(id) {
      if (!id) return
      if (id === 'partners-history') this.$router.push('/partners-history/all')
      if (id === 'clients-history') this.$router.push('/clients-history/all')
    },

    // deleteSelected(isAllRows) {
    //   this.deleteRow(isAllRows ? ['all'] : this.selectedRows)
    // },

    saveNewEntity(entity) {
      this.setNewEntity(entity)
      this.addRow()
    },

    // async editRow(entity) {
    //    this.$store.commit('setIsLoading', true)
    //     const id = entity.id
    //     delete entity.id

    //    let result = await fetchData( urls.partners+`/${id}`, 'POST', null, this.entityQuery(entity))
    //    if(!result?.detail) {
    //       let entityRow = this.rows.find(item => item.id === id)
    //       for (const key in entity) {
    //         if(key in entityRow)
    //         entityRow[key] = entity[key]
    //       }

    //       this.isHideNewRowComponent = true
    //       this.$nextTick(() => { this.isHideNewRowComponent = false })
    //    }
    //    else console.error(result.detail)

    //    this.$store.commit('setIsLoading', false)
    // },

    // async addRow() {
    //    this.$store.commit('setIsLoading', true)

    //    let result = await fetchData( urls.partners, 'POST', null, this.entityQuery() )
    //    if(!result?.detail) {
    //       const tmpEntity = this.newEntity
    //       this.rows.unshift({ ...{id: result.id}, ...tmpEntity })
    //       this.isHideNewRowComponent = true
    //       this.$nextTick(() => { this.isHideNewRowComponent = false })

    //       this.newEntityToDefault()
    //    }
    //    else {
    //     this.isHideNewRowComponent = false
    //     console.error(result.detail)
    //    }

    //    this.$store.commit('setIsLoading', false)
    // },

    // async deleteRow(ids) {
    //   let idsLocal = typeof ids === 'object' ? ids : [ids]
    //    if(!idsLocal || !idsLocal.length) return

    //    this.$store.commit('setIsLoading', true)

    //    const deleteEntityQuery = ids => {
    //     let query = ''
    //     for (const id of ids) {
    //       query += `history_ids=${id}&`
    //     }

    //     return query
    //    }

    //    let query = ''

    //    if(idsLocal?.length && idsLocal[0] === 'all') {
    //      query += query = `${this.queryToFetch(1, 'all')}&${this.queryToFilters()}&${this.queryToSort()&deleteEntityQuery(idsLocal)}`
    //    }
    //    else {
    //      query += deleteEntityQuery(idsLocal)
    //    }

    //    let result = await fetchData( urls.partners, 'DELETE', null, query)
    //    if(!result?.detail){
    //     for (const id of idsLocal) {
    //       const idx = this.rows.findIndex(item => item.id === id)
    //       this.rows.splice(idx, 1)
    //     }
    //       this.selectedRows = []
    //    }
    //    else console.error(result.detail)

    //    this.$store.commit('setIsLoading', false)
    // },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = '&'
        for (const id of ids) {
          query += `history_ids=${id}&`
        }

        if (query === '&') {
          for (const row of this.rows) {
            query += `history_ids=${row.id}&`
          }
        }

        // if(this.options.isShowAllColumns) {
        //   query += `all_columns=true&`
        // }
        // else {
        //   query += `all_columns=false&`
        // }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(
        urls.clientsHistoryToCsv + `/${this.entityId || ''}`,
        'GET',
        'history',
        null,
        query
      )

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      this.$nextTick(() => {
        this.setHeightGridScrolableBlock()
      })
      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = ''
      let prevSearch_text = this.search_text.value

      query = `${this.queryToFetch()}&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(
        urls.clientsHistory + `/${this.entityId || ''}`,
        'GET',
        null,
        query
      )

      this.rows = result?.rows || []

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
